import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Newsletters } from '../../../models/newsletters';

import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {

  constructor(public snackBar: MatSnackBar,  private db: AngularFirestore) { }

  usuario = {} as Newsletters;

  ngOnInit() {
  }
  addUsuario(){
    let exists = false;
    this.db.collection('Boletines').get().subscribe((users) => {
      users.docs.forEach((user) => {
        const usuario = user.data();
        if(usuario.email == this.usuario.email || !this.usuario.email){
          exists = true;
        }
      });

      if (!exists){
        console.log(this.usuario);
        this.db.collection('Boletines').add(this.usuario)
        .then(result => {
          this.snackBar.open(`Ha sido agregado correctamente al boletin de noticias`, 'x', {panelClass: ['success'], verticalPosition: 'top', duration: 3000 });
        })
      }
      else {
        this.snackBar.open(`El usuario ${this.usuario.email} ya existe`, 'x', {panelClass: ['success'], verticalPosition: 'top', duration: 3000 });
        console.log('El usuario ya existe');
      }
    })
    
  }

}
