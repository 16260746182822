import { Component, OnInit, ɵConsole } from '@angular/core';
import { Product } from 'src/app/models/product.model';
import { CartItem } from 'src/app/models/cart-item';
import { ProductService } from 'src/app/services/product.service';
import { CartService } from 'src/app/services/cart.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
  styleUrls: ['./home-two.component.sass']
})
export class HomeTwoComponent implements OnInit {

  public products: Product[];
  public slides = [];

  shoppingCartItems: CartItem[] = [];
  wishlistItems  :   Product[] = [];

  public featuredProducts: Array<Product>;
  public onSaleProducts: Array<Product>;
  public topRatedProducts: Array<Product>;
  public newArrivalsProducts: Array<Product>;
  public categorias = JSON.parse(localStorage.getItem('categoriasLaTiendaSolidaria'));

constructor(private productService: ProductService, private cartService: CartService, private router: Router) { }

  ngOnInit() {
    if (location.pathname == '/'){
      this.router.navigate(['home'])
    }
    this.products=[];
    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);    
    this.productService.getProductosConLimite('10').subscribe((products: any) => {
      this.products = [];
      products.forEach(producto => {
        const categoria = this.categorias.find(categoria => categoria.id == producto.data.TallerLineaProducto)

        if(producto.data.Valor > 0) {
          this.products.push({
            id: producto.id,
            name: producto.data.Nombre,
            price: producto.data.Valor,
            salePrice: producto.data.ValorConDescuento,
            discount: producto.data.PorcentajeDescuento,
            pictures: [{
              big: producto.data.ImgUrl,
              small: producto.data.ImgUrl,
            }], 
            shortDetails: producto.data.DescripcionCorta,
            description: producto.data.Descripcion,
            stock: producto.data.Stock,
            brand: producto.data.Taller,
            sale: (producto.data.Saldo == 'Si') ? true : false,
            category: categoria.name,
            user: producto.data.User,
            stars: producto.data.Estrellas
          })
        }
      })
    
  })

    this.productService.getBanner().subscribe((banner: any) => {
      this.slides = [];
      banner.forEach(bann => {
        this.slides.push({
          image: bann.data.image,
          subtitle: bann.data.subtitle,
          title: bann.data.title,
          url: bann.data.url
        })
      })
    })
  } 
}
