import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/models/product.model';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {
  products: Product[];
  public banners = [];

  constructor(private productService: ProductService) { }

  ngOnInit() {
    this.productService.getBanners().subscribe((data:any) => {
        data.docs.forEach((item) => {
          this.banners.push({image:item.data().image,subtitle:item.data().subtitle,title:item.data().title});
        });
      }
    );
    this.productService.getProducts().subscribe((products: any) => {this.products = products.docs})
  }
}