import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Product } from 'src/app/models/product.model';
import { ProductService } from 'src/app/services/product.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CartService } from 'src/app/services/cart.service';
import { SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ProductZoomComponent } from './product-zoom/product-zoom.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.sass']
})
export class ProductDetailsComponent implements OnInit {

  public config: SwiperConfigInterface={};
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @ViewChild('zoomViewer', { static: true }) zoomViewer;
  @ViewChild(SwiperDirective, { static: true }) directiveRef: SwiperDirective;

  public product:Product = {};
  public products:Product[] = [];
  public image: any;
  public zoomImage: any;
  public counter:number = 1;
  index: number;
  bigProductImageIndex = 0;

  public reviews: any[] = [];
  public currentUser: any;
  public isUser: boolean = false;
  public categorias = JSON.parse(localStorage.getItem('categoriasLaTiendaSolidaria'));

  
  reviewForm = new FormGroup({
    idProd: new FormControl(''),
    name: new FormControl('', [Validators.required],),
    email: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', [Validators.required]),
    date: new FormControl('')
  })


  constructor(private route: ActivatedRoute, 
              public productsService: ProductService, 
              public dialog: MatDialog, 
              private router: Router, 
              private cartService: CartService,
              private db: AngularFirestore,
              private authSvc: AuthService,
              ) {
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.productsService.getProducto(id).subscribe((product: any) => {
        const categoria = this.categorias.find(categoria => categoria.id == product.data.TallerLineaProducto)
        this.product = {
          id: product.id,
          name: product.data.Nombre,
          price: product.data.Valor,
          salePrice: product.data.ValorConDescuento,
          discount: product.data.PorcentajeDescuento,
          pictures: [{
            big: product.data.ImgUrl,
            small: product.data.ImgUrl,
          }],
          shortDetails: product.data.DescripcionCorta,
          description: product.data.Descripcion,
          stock: product.data.Stock,
          brand: product.data.Taller,
          sale: product.data.Saldo,
          category: categoria.name,
          user: product.data.User
        }
        //this.getCurrentUser();
        //this.getReviews();

        
      })
    });
   }

  ngOnInit() {
    // this.productsService.getProducts().subscribe(products => {
    //   products.docs.forEach((item) => {
    //     this.products.push(item.data());
    //     this.getRelatedProducts();
    //   });
    // });
  }
  public getReviews(){
    this.reviews = [];
    this.db.collection('reviews', ref => ref.where('idProd', '==', this.product.id)).get()
    .subscribe(reviews => {
      reviews.docs.forEach((review) => {
        this.reviews.push(review.data());
      })
  
    })
  }

  public getCurrentUser(){
    this.authSvc.afAuth.onAuthStateChanged(user => {
      if(user){
        
        this.db.collection('usuarios', ref => ref.where('email', '==', user.email)).get()
        .subscribe(usuario => {
  
          this.db.collection('ordenes', ref => ref.where('data_epayco_email_billing', '==', user.email).where('data_epayco_state', '==', 'Finalizado')).get()
          .subscribe(compro => {
            if(!compro.empty){
              this.isUser = true;
              this.currentUser = usuario.docs[0].data();
              this.reviewForm.controls.name.disable();
              this.reviewForm.controls.email.disable();
               
            }
          })
                
        })
      }
    })
  }



  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 3,
      spaceBetween: 10,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 3,
        },


      }
    }
  }


  public openProductDialog(product, bigProductImageIndex) {
    let dialogRef = this.dialog.open(ProductZoomComponent, {
      data: {product, index: bigProductImageIndex },
      panelClass: 'product-dialog',
    });
    dialogRef.afterClosed().subscribe(product => {
      if (product) {
        this.router.navigate(['/products', product.id, product.name]);
      }
    });
  }


  public selectImage(index) {
    this.bigProductImageIndex = index;
  }


public increment() {
  this.counter += 1;
}

public decrement() {
  if(this.counter >1){
     this.counter -= 1;
  }
}

getRelatedProducts() {
  this.productsService.getProducts().subscribe((products: any) => {
    products.docs.forEach((item) => {
      this.products.push(item.data());
      this.getRelatedProducts();
    });
  });
}

  // Add to cart
  public addToCart(product: Product, quantity) {
    if (quantity == 0) return false;
    this.cartService.addToCart(product, parseInt(quantity));
  }

   // Add to cart
   public buyNow(product: Product, quantity) {
    if (quantity > 0)
      this.cartService.addToCart(product,parseInt(quantity));
      this.router.navigate(['/pages/checkout']);
 }



 public onMouseMove(e){
  if(window.innerWidth >= 1280){
    var image, offsetX, offsetY, x, y, zoomer;
    image = e.currentTarget;
    offsetX = e.offsetX;
    offsetY = e.offsetY;
    x = offsetX/image.offsetWidth*100;
    y = offsetY/image.offsetHeight*100;
    zoomer = this.zoomViewer.nativeElement.children[0];
    if(zoomer){
      zoomer.style.backgroundPosition = x + '% ' + y + '%';
      zoomer.style.display = "block";
      zoomer.style.height = image.height + 'px';
      zoomer.style.width = image.width + 'px';
    }
  }
}

public onMouseLeave(event){
  this.zoomViewer.nativeElement.children[0].style.display = "none";
}

public openZoomViewer(){
  this.dialog.open(ProductZoomComponent, {
    data: this.zoomImage,
    panelClass: 'zoom-dialog'
  });
}



}


