import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscriber, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Product } from 'src/app/models/product.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import random from '@angular-devkit/schematics/src/rules/random';
import { AngularFirestore } from '@angular/fire/firestore';

let products = JSON.parse(localStorage.getItem("compareItem")) || [];

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public currency : string = 'USD';
  public catalogMode : boolean = false;

  private _url: string = "assets/data/";
  public url = "assets/data/banners.json";

  public compareProducts : BehaviorSubject<Product[]> = new BehaviorSubject([]);
  public observer   :  Subscriber<{}>;
  public bannerItems:any[];
  header = new HttpHeaders();

  public prodMap: Product[];
  public categorias: any[] = [];

  private adminAPI = 'https://us-central1-ecommerceadmindemo.cloudfunctions.net/app';
  adminHeader = new HttpHeaders()

  constructor(private httpClient: HttpClient, public snackBar: MatSnackBar,private db: AngularFirestore) {
    this.compareProducts.subscribe(products => products = products)
    this.adminHeader.append('Content-Type','application/json')
  }

  // FIREBASE FUNCTIONS API
  public updateProducto(id: any, product: any){
    return this.httpClient.put(`${this.adminAPI}/productos/${id}`, product)
  }

  public getProducto(id: any){
    return this.httpClient.get(`${this.adminAPI}/productos/${id}`)
  }

  public getProductos(){
    return this.httpClient.get(`${this.adminAPI}/productos`)
  }

  public getProductoPorCategoria(categoria: any){
    return this.httpClient.get(`${this.adminAPI}/productos/categoria/${categoria}`)
  }

  public getProductosConLimite(limit){
    return this.httpClient.get(`${this.adminAPI}/productos/limit/${limit}`)
  }

  public getBanner(){
    return this.httpClient.get(`${this.adminAPI}/banner`)
  }

  public getCategorias(){
    return this.httpClient.get(`${this.adminAPI}/categorias`)
  }
  //

  getCategories(){
    return this.db.collection('Parametros').get();
  }

  getBanners(){
    return this.db.collection('Banners').get();
  }

  getProducts(){      
    return this.db.collection('Productos').get();
  }

  getProductsById(id){
    return this.db.collection('Productos' ,ref => ref.where('id', '==', id)).get();
  }

  getProductsByCategory(categoria){ 
    if(categoria!="all")
    {
      return this.db.collection('Productos',ref => ref.where('category', '==', categoria)).get();
    }
    else
    {
      return this.db.collection('Productos').get();     
    }
  }

  // Get Compare Products
  public getComapreProducts(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(products);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // If item is aleready added In compare
  public hasProduct(product: Product): boolean {
    const item = products.find(item => item.id === product.id);
    return item !== undefined;
  }

 // Add to compare
 public addToCompare(product: Product): Product | boolean {
    let message, status;
    var item: Product | boolean = false;
    if (this.hasProduct(product)) {
      item = products.filter(item => item.id === product.id)[0];
      const index = products.indexOf(item);
      this.snackBar.open('El producto  ' + product.name + ' ya lo adicionaste a la lista de comparación.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });

    } else {
      if(products.length < 4)
        products.push(product);
        message = 'Adicionaste el producto ' + product.name + ' a la lista de comparación.';
        status = 'success';
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });

    }
      localStorage.setItem("compareItem", JSON.stringify(products));
      return item;
  }

  // Removed Product
  public removeFromCompare(product: Product) {
    if (product === undefined) { return; }
    const index = products.indexOf(product);
    products.splice(index, 1);
    localStorage.setItem("compareItem", JSON.stringify(products));
  }
  
  public updateStock(id: string, stock: number){
    const patchStock = 
    {
      "fields": {
        "Stock": {
          "integerValue": stock
        }
      }
    }
    this.httpClient.patch(`https://firestore.googleapis.com/v1beta1/projects/ecommerceadmindemo/databases/(default)/documents/Productos/${id}?updateMask.fieldPaths=Stock`, patchStock, {headers: this.header})
    .subscribe((patch) => {
      return patch
    })
  }
}
