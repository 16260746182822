import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user: any;
  public authState: any;

  constructor(public afAuth: AngularFireAuth, private db: AngularFirestore) {
    this.user = this.afAuth.authState.pipe(
      switchMap((user) => {
        if(user) {
          return this.db.doc<any>(`Usuarios/${user.uid}`).valueChanges();
        }
      })
    )
  }

  async register(user): Promise<any> {
    const result = await this.afAuth.createUserWithEmailAndPassword( user.email, user.password)
   .then( userCredential => {
    this.user = user;
    this.insertData(userCredential)
    .then(() => {
      return result
    })
   })
   .catch( err => {
     console.log(err);
   })  
  }

  async insertData(userData: firebase.auth.UserCredential){
    return this.db.doc<any>(`Usuarios/${userData.user.uid}`).set({
      name: this.user.name,
      address: this.user.address,
      cellphone: this.user.cellphone,
      email: this.user.email
    })
  }

  async login(email: string, password: string): Promise<any> {
    try {
      const result = await this.afAuth.signInWithEmailAndPassword(
        email,
        password
      );
      return result;
    } catch(error){
      console.log(error);

    }  
  }
  
  async logout(): Promise<void> {
    try {
      await this.afAuth.signOut();
    }
    catch(err) {
      console.log(err);
    }
  }
}
