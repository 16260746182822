import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Product, ColorFilter } from 'src/app/models/product.model';

@Component({
  selector: 'app-product-left-sidebar',
  templateUrl: './product-left-sidebar.component.html',
  styleUrls: ['./product-left-sidebar.component.sass']
})
export class ProductLeftSidebarComponent implements OnInit {
  public sidenavOpen:boolean = true;
  public animation:any;   
  public sortByOrder:string = '';
  public page:any;
  public tagsFilters:any[] = [];
  public viewType:string = 'grid';
  public viewCol:number = 25;
  public colorFilters:ColorFilter[] = [];
  public items:Product[] = [];
  public allItems:Product[] = [];
  public products:Product[] = [];
  public tags:any[] = [];
  public colors:any[] = [];
  public categorias = JSON.parse(localStorage.getItem('categoriasLaTiendaSolidaria'));


  constructor(private productService: ProductService, private route: ActivatedRoute) {
    
  }

  ngOnInit() {  
    this.route.params.subscribe(
      (params: Params) => {
        const category = params['category'].replace('/', '%2F');
        this.productService.getProductoPorCategoria(category).subscribe((products:any) => {
          this.allItems = [];
          products.forEach((prod) => {
            const categoria = this.categorias.find(categoria => categoria.id == prod.data.TallerLineaProducto)

            //let idProd = prod.document.name.split('/')
            // Validaciones
            if (prod.data.Nombre != '' && prod.data.Valor > 0){
              this.allItems.push({  
                id: prod.id,
                name: prod.data.Nombre,
                price: prod.data.Valor,
                salePrice: prod.data.ValorConDescuento,
                discount: prod.data.PorcentajeDescuento,
                pictures: [{
                  big: prod.data.ImgUrl,
                  small: prod.data.ImgUrl,
                }], 
                shortDetails: prod.data.DescripcionCorta,
                description: prod.data.Descripcion,
                stock: prod.data.Stock,
                brand: prod.data.Taller,
                sale: (prod.data.Saldo == 'Si') ? true : false,
                category: categoria.name,
                user: prod.data.User,
                stars: (prod.data.Estrellas == undefined ) ? 0 : prod.data.Estrellas  
              })
            }   
          })
          this.products = this.allItems.slice(0.8)
        })
      }
    )
  }

  // Get current product tags
  public getTags(products) {
      var uniqueBrands = []
      var itemBrand = Array();
      products.map((product, index) => {
         if(product.tags) {
            product.tags.map((tag) => {
            const index = uniqueBrands.indexOf(tag);
            if(index === -1)  uniqueBrands.push(tag);
         })
        }
      });
      for (var i = 0; i < uniqueBrands.length; i++) {
           itemBrand.push({brand:uniqueBrands[i]})
      }
      this.tags = itemBrand
   }

     // Get current product colors
     public getColors(products) {
      var uniqueColors = []
      var itemColor = Array();
      products.map((product, index) => {
        if(product.colors) {
        product.colors.map((color) => {
            const index = uniqueColors.indexOf(color);
            if(index === -1)  uniqueColors.push(color);
        })
       }
      });
      for (var i = 0; i < uniqueColors.length; i++) {
           itemColor.push({color:uniqueColors[i]})
      }
      this.colors = itemColor
   }

 

  public changeViewType(viewType, viewCol){
    this.viewType = viewType;
    this.viewCol = viewCol;
  }

  // Animation Effect fadeIn
  public fadeIn() {
      this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
      this.animation = 'fadeOut';
  }

  // Update tags filter
  public updateTagFilters(tags: any[]) {
      this.tagsFilters = tags;
      this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }



  // sorting type ASC / DESC / A-Z / Z-A etc.
  public onChangeSorting(val) {
      this.sortByOrder = val;
      this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
   }

  // Initialize filetr Items
  public filterItems(): Product[] {
    return this.items.filter((item: Product) => {
        const Colors: boolean = this.colorFilters.reduce((prev, curr) => { // Match Color
          if(item.colors){
            if (item.colors.includes(curr.color)) {
              return prev && true;
            }
          }
        }, true);
        const Tags: boolean = this.tagsFilters.reduce((prev, curr) => { // Match Tags
          if(item.tags) {
            if (item.tags.includes(curr)) {
              return prev && true;
            }
          }
        }, true);
        return Colors && Tags; // return true
    });
}

public onPageChanged(event){
  this.page = event;
  this.allItems;
  window.scrollTo(0,0);
}


  // Update price filter
  public updatePriceFilters(price: any) {


   this.allItems = this.products.filter((item: Product) => {
     return item.price >= price.priceFrom && item.price <= price.priceTo
    });

}

  onBrendsChanged(newBrend) {
    this.allItems = newBrend === 'all' ? this.products : this.products.filter(

      item => item.brand === newBrend
    )
  }
}
