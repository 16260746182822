import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.sass']
})
export class CategoriesComponent implements OnInit {
  public categories: any[] = []

  constructor(public prodSvc: ProductService) { }

  ngOnInit() {
    this.prodSvc.getCategorias().subscribe((categorias: any) => {
      categorias.forEach(categoria => {
        this.categories.push({
          name: categoria.data.name,
          id: categoria.id
        })
      })
    })
  }
}
