import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxImgZoomModule } from 'ngx-img-zoom';

// Firebase Modules
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage'
import { AngularFireAuthModule, AngularFireAuth } from '@angular/fire/auth';

import { MainComponent } from './components/main/main.component';

import { AppRoutingModule } from './app-routing.module';
import { ShopModule } from './components/shop/shop.module';
import { SharedModule } from './components/shared/shared.module';

const firebaseConfig = {
  apiKey: "AIzaSyBLKyjxsFXCYq7kqZYc1iUIznJVMfKR-l8",
  authDomain: "ecommerceportaldemo.firebaseapp.com",
  databaseURL: "https://ecommerceportaldemo.firebaseio.com",
  projectId: "ecommerceportaldemo",
  storageBucket: "ecommerceportaldemo.appspot.com",
  messagingSenderId: "379443304215",
  appId: "1:379443304215:web:c64b0d735194a965f78952",
  measurementId: "G-RVE2EYLK3D"
};

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
  ],
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    SharedModule,
    ShopModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxImgZoomModule,
    //Firebase
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
