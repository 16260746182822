import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/models/product.model';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-popular-products',
  templateUrl: './popular-products.component.html',
  styleUrls: ['./popular-products.component.sass']
})
export class PopularProductsComponent implements OnInit {

  public products: Product[];
  public slides = [];
 
   constructor(private productService: ProductService) { }
 
   ngOnInit() {
    this.products = [];
    this.productService.getProductos().subscribe((data: any) => {
      data.forEach((prod) => {
        this.products.push({
                id: prod.id,
                name: prod.data.Nombre,
                price: prod.data.Valor,
                salePrice: prod.data.ValorConDescuento,
                discount: prod.data.PorcentajeDescuento,
                pictures: [{
                  big: prod.data.ImgUrl,
                  small: prod.data.ImgUrl,
                }], 
                shortDetails: prod.data.DescripcionCorta,
                description: prod.data.Descripcion,
                stock: prod.data.Stock,
                brand: prod.data.Taller,
                sale: (prod.data.Saldo == 'Si') ? true : false,
                category: prod.data.TallerLineaProducto,
                user: prod.data.User,
                stars: (prod.data.Estrellas == undefined ) ? 0 : prod.data.Estrellas  
        });
      });
    });
   }
}